import React from 'react';
import {takeLatest, call, put, select} from 'redux-saga/effects';
import {parse as parseQueryString} from 'qs';
import {navigate} from 'gatsby';

import {
    SUBMIT_FORM,
    SUBMIT_ACTIVATION_TOKEN,
    RESEND_ACTIVATION_EMAIL,
    submitFormSuccess,
    submitFormFailure,
    submitActivationTokenFailure,
    submitActivationTokenSuccess,
    resendActivationEmailSuccess,
    resendActivationEmailFailure,
    setReferralCodeActive,
} from './actions';
import {fetch} from '../backend-api';
import {LOCATION_CHANGE} from '../navigation/actions';
import {renderBackendApiError} from '../../utils/error-utils';
import {routes} from '../navigation/routes';
import toast, {Notification} from '../../ui/components/Toast';
import lang from '../../lang/notification.lang';
import {selectTempData} from '../profile';
import {selectRedirectUrl} from './selectors';

function* postSubmitRegisterForm(userData) {
    return yield call(fetch, SUBMIT_FORM, 'POST', '/register', userData);
}

function* postSubmitActivationToken(token) {
    return yield call(fetch, SUBMIT_ACTIVATION_TOKEN, 'POST', '/activate', {
        token,
    });
}

function* postResendActivationEmail(username) {
    return yield call(
        fetch,
        RESEND_ACTIVATION_EMAIL,
        'POST',
        '/activate/resend',
        {username}
    );
}

function* resendActivationEmailSaga({payload}) {
    try {
        const {username} = payload;
        yield call(postResendActivationEmail, username);
        yield put(resendActivationEmailSuccess());
        toast.info(
            <Notification headline={lang.register.resendEmail.success.hl}>
                {lang.register.resendEmail.success.msg}
            </Notification>
        );
    } catch (error) {
        yield put(resendActivationEmailFailure(error));
        toast.error(
            <Notification headline={lang.register.resendEmail.failure.hl}>
                {renderBackendApiError(error)}
            </Notification>
        );
    }
}

function* submitActivationTokenSaga({payload}) {
    try {
        const {location} = payload;
        const {token, redirect_uri} = parseQueryString(location.search, {
            ignoreQueryPrefix: true,
        });
        const {data: userData} = yield call(postSubmitActivationToken, token);
        yield put(submitActivationTokenSuccess(userData));
        if (userData.has_used_referral_code) {
            yield put(setReferralCodeActive());
        }

        navigate(redirect_uri || routes.trainings);
        toast.info(
            <Notification headline={lang.register.submitToken.success.hl}>
                {lang.register.submitToken.success.msg}
            </Notification>
        );
    } catch (error) {
        yield put(submitActivationTokenFailure(error));
        // navigate(routes.impressum);
        toast.error(
            <Notification headline={lang.register.submitToken.failure.hl}>
                {renderBackendApiError(error)}
            </Notification>
        );
        navigate(routes.start);
    }
}

function* submitRegisterFormSaga({payload}) {
    try {
        const {userData} = payload;
        const userTempData = yield select(selectTempData);
        const redirectUrl = yield select(selectRedirectUrl);
        if (redirectUrl) {
            userData.redirect_uri = redirectUrl;
            userData.source =
                redirectUrl === routes.apothekentour ? 'pharmacy_tour' : '';
        }
        if (userTempData.userType || userTempData.categories) {
            yield call(postSubmitRegisterForm, {
                ...userData,
                categories: userTempData.categories,
                type: userTempData.userType,
            });
        } else {
            yield call(postSubmitRegisterForm, userData);
        }

        yield put(submitFormSuccess());
        toast.info(
            <Notification headline={lang.register.form.success.hl}>
                {lang.register.form.success.msg}
            </Notification>
        );
        navigate(routes.start);
    } catch (error) {
        yield put(submitFormFailure(error));
        toast.error(
            <Notification headline={lang.register.form.failure.hl}>
                {renderBackendApiError(error)}
            </Notification>
        );
    }
}

// NOTE: the slash between the routes.aktivieren and the ? of the query parameter
// is important here because whoever (backend, browser or client router) rewrites the url
// (from ..aktivieren?.. to ..aktivieren/?..), so that a second LOCATION_CHANGE will occur
// after the initial one. With takeLatest, that causes the previous submitActivationTokenSaga
// to be cancelled right after the request so that {data: userData} is suddenly undefined.
// To prevent this from happening, we declare only the second, rewritten location as valid:
const isActivationRoute = (action) => {
    return (
        action.type === LOCATION_CHANGE &&
        action.payload.location.pathname === routes.aktivieren
    );
};

export const internals = {
    submitRegisterFormSaga,
    submitActivationTokenSaga,
    postSubmitActivationToken,
    resendActivationEmailSaga,
    postResendActivationEmail,
    isActivationRoute,
    postSubmitRegisterForm,
};

export default function* registrationSaga() {
    yield takeLatest(SUBMIT_FORM, submitRegisterFormSaga);
    yield takeLatest(isActivationRoute, submitActivationTokenSaga);
    yield takeLatest(RESEND_ACTIVATION_EMAIL, resendActivationEmailSaga);
}
